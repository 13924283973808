@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeueHeavy.woff2') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeueBold.woff2') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeueMedium.woff2') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeueRoman.woff2') format('opentype');
  font-weight: 400;
  font-style: normal;
}

body {
  @apply bg-secondary;
  font-family: 'Helvetica Neue', sans-serif;
}

#root {
  min-height: 100svh;
  @apply flex flex-col;
}

@layer components {
  .logo-cube-shadow {
    filter: drop-shadow(0px 4px 16px #2993f540)
      drop-shadow(0px 4px 8px #2993f540);
  }

  .base-cell {
    @apply px-6 py-3 max-lg:px-3 max-md:px-0 max-md:py-2;
  }

  .base-cell.skeleton {
    @apply py-3.5 max-md:py-2;
  }

  .left-cell {
    @apply pl-0;
  }

  .right-cell {
    @apply pr-0;
  }

  .table-cell-interaction {
    @apply animate-new cursor-pointer transition-colors group-hover/row:text-link group-active/row:text-link;
  }

  .link {
    @apply text-link decoration-1 underline-offset-2 transition-colors hover:underline;
  }

  .fade-enter {
    @apply opacity-0;
  }

  .fade-enter-active {
    @apply opacity-100 transition-all duration-300;
  }

  .fade-exit {
    @apply opacity-100;
  }

  .fade-exit-active {
    @apply opacity-0 transition-all duration-300;
  }

  .button-hover {
    @apply hover:bg-lightBlue/80 active:bg-lightBlue/80;
  }

  .content {
    @apply flex flex-col gap-6 pb-16 pt-6 max-md:pb-10;
  }
}

body:has(div[role='dialog']) {
  @apply overflow-hidden;
}

li::before {
  @apply mr-3 text-xs content-["\2022"];
}
